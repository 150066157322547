import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import StatsPage from './pages/StatsPage'


const RedirectToHomePage = () => {
  window.location = 'https://www.carebywally.com'
  return null
}

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<RedirectToHomePage />} />
      <Route path="stats/:referralCode" element={<StatsPage />} />
      <Route path="*" element={<RedirectToHomePage />} />
    </Routes>
  </BrowserRouter>
)

export default Router
