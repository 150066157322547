import React from 'react'
import ReactDOM from 'react-dom/client'
import Router from './router'
import { NotificationProvider } from '@wallyhealth/web-core'
import './index.css'
import '@wallyhealth/web-core/dist/fonts/index.css'


const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <NotificationProvider>
      <Router />
    </NotificationProvider>
  </React.StrictMode>
)
