import { useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { countBy, times } from 'ramda'
import { FullPage, Button, ButtonSize, ButtonType, Modal } from '@wallyhealth/web-core'
import { ScatterChart, Legend, Scatter, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import RechartsWrapper from '../../components/RechartsWrapper'
import { useStats } from './hooks'
import { pluralize } from 'humanize-plus'
import arrowSrc from './arrow.svg'
import styles from './index.module.css'


const toTimeSpanResolver = (monthIndex) => (
  moment().subtract(monthIndex, 'month')
)
const MONTHS_COUNT = 6

const toLabel = (resolver) => (
  resolver.format('MMM \'YY')
)

export const TimeSpanResolvers = times(toTimeSpanResolver, MONTHS_COUNT)

export const TimeSpans = TimeSpanResolvers.map((resolver, index) => ({
  value: index,
  label: toLabel(resolver)
}))


const isInt = (value) => {
  return value % 1 === 0
}

const formatYLabel = (label) => {
  return (!isInt(label) || label === 0) ? '' : label
}


const generateAllDatesInMonth = ({ daysInMonth, year, month }) => {
  const dayMapper = (dayInMonth) => (
    `${month}/${dayInMonth + 1}/${year}`
  )
  return times(dayMapper, daysInMonth)
}

const resolveConversionFrequency = ({ leads = [], timeSpanResolver, referralCodeType }) => {

  const allDatesInMonth = generateAllDatesInMonth({
    daysInMonth: timeSpanResolver.daysInMonth(),
    year: timeSpanResolver.year(),
    month: timeSpanResolver.month() + 1
  })

  const convertedDates = leads.map(l => moment(l.convertedAt).format('l'))
  const convertedDateCountPairs = countBy(i => i)(convertedDates)

  return allDatesInMonth.map(date => ({
    day: moment(date).date(),
    count: convertedDateCountPairs[date] || 0,
    referralCodeType
  }))
}

const Bullet = ({ fill }) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
    <circle cx="5" cy="5" r="5" fill={fill} />
  </svg>
)

const CustomLegend = ({ payload }) => (
  <ul className={styles.legend}>
    {payload.map((entry, index) => (

      <li key={`legend-item-${index}`} className={styles.legend_item}>
        <Bullet fill={entry.color} />
        <span className={styles.legend_text}>
          {entry.value}
        </span>
      </li>

    ))}
  </ul>
)

const MonthSelect = ({ selectedIndex, onSelectedIndexChanged }) => (
  <div className={styles.selector}>
    <button
      className={styles.selector_button}
      disabled={selectedIndex >= (TimeSpans.length - 1)}
      onClick={() => onSelectedIndexChanged(selectedIndex + 1)}>
      <img
        className={styles.arrow_left}
        src={arrowSrc}
        alt="arrow"
      />
    </button>
    <span>
      in {TimeSpans[selectedIndex].label}
    </span>

    <button
      className={styles.selector_button}
      disabled={selectedIndex <= 0}
      onClick={() => onSelectedIndexChanged(selectedIndex - 1)}>
      <img
        className={styles.arrow_right}
        src={arrowSrc}
        alt="arrow"
      />
    </button>
  </div>
)

const Title = ({ leadCount, timeSpanResolverIndex, setTimeSpanResolverIndex }) => {

  return (
    <div className={styles.metrics}>

      <span className={styles.metrics_count}>
        {leadCount} {pluralize(leadCount, 'conversion')}
      </span>

      <MonthSelect
        selectedIndex={timeSpanResolverIndex}
        onSelectedIndexChanged={(index) => setTimeSpanResolverIndex(index)}
      />

    </div>
  )
}


const ScatterPoint = ({ cx, cy, payload, fill }) => {

  if (!payload.count) {
    return null
  }

  const SIZE = {
    DIRECT: 26,
    INDIRECT: 18
  }

  const size = SIZE[payload.referralCodeType]
  
  return (
    <svg x={cx - size/2} y={cy - size/2} width={size} height={size}>
      <circle cx={size/2} cy={size/2} r={size/4} fill={fill} />
    </svg>
  )
}


const StatsPage = () => {

  const { referralCode } = useParams()
  const [areMetricsVisible, setAreMetricsVisible] = useState(false)
  const [timeSpanResolverIndex, setTimeSpanResolverIndex] = useState(0)
  const timeSpanResolver = TimeSpanResolvers[timeSpanResolverIndex]

  const formatXLabel = (day) => {
    return `${timeSpanResolver.month() + 1}/${day}`
  }

  const { convertedLeads } = useStats({
    referralCode,
    timeSpanMonth: timeSpanResolver.month() + 1,
    timeSpanYear: timeSpanResolver.year()
  })

  const directConversionData = resolveConversionFrequency({
    leads: convertedLeads.filter(lead => lead.referralCodeType === 'DIRECT'),
    timeSpanResolver,
    referralCodeType: 'DIRECT'
  })

  const indirectConversionData = resolveConversionFrequency({
    leads: convertedLeads.filter(lead => lead.referralCodeType === 'INDIRECT'),
    timeSpanResolver,
    referralCodeType: 'INDIRECT'
  })

  return (
    <>
      <FullPage>
        <div className={styles.container}>
          <Title
            leadCount={convertedLeads.length}
            timeSpanResolverIndex={timeSpanResolverIndex}
            setTimeSpanResolverIndex={setTimeSpanResolverIndex}
          />
          <Button
            className={styles.button}
            label="View timeline"
            size={ButtonSize.LARGE}
            type={ButtonType.TERNARY}
            onClick={() => setAreMetricsVisible(true)}
            disabled={convertedLeads.length === 0}
          />
        </div>
      </FullPage>

      <Modal
        containerClassName={styles.modal}
        bodyClassName={styles.modal_body}
        isOpen={areMetricsVisible}
        onClosed={() => setAreMetricsVisible(false)}>

        <RechartsWrapper className={styles.chart}>
          <ResponsiveContainer>

            <ScatterChart 
              margin={{ left: -60 }}>

              <CartesianGrid
                strokeDasharray="6"
              />
              <XAxis
                type="number"
                dataKey="day"
                tickFormatter={formatXLabel} 
                tickLine={false}
              />
              <YAxis
                type="number"
                dataKey="count"
                tickFormatter={formatYLabel}
                tickLine={false}
              />
              <Scatter
                name="Direct"
                data={directConversionData}
                fill="#106A5C"
                shape={ScatterPoint}
              />
              <Scatter
                name="Indirect"
                data={indirectConversionData}
                fill="#8AC8BC"
                shape={ScatterPoint}
              />
              <Legend
                content={<CustomLegend />}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </RechartsWrapper>

      </Modal>
    </>
  )
}

export default StatsPage