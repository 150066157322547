import { useEffect, useState } from 'react'
import { fetchConvertedLeads } from './api'
import { useNotifications } from '@wallyhealth/web-core'


export const useStats = ({ referralCode, timeSpanMonth, timeSpanYear }) => {

  const [convertedLeads, setConvertedLeads] = useState([])
  const notifications = useNotifications()

  const resolveConvertedLeads1 = async () => {
    try {
      await resolveConvertedLeads2()
    } catch (err) {
      notifications.error('Something went wrong, please refresh the page.')
    }
  }
  const resolveConvertedLeads2 = async () => {
    const result = await fetchConvertedLeads({
      code: referralCode,
      month: timeSpanMonth,
      year: timeSpanYear
    })
    setConvertedLeads(result)
  }
  useEffect(() => {
    resolveConvertedLeads1()
  }, [referralCode, timeSpanMonth, timeSpanYear])


  return {
    convertedLeads
  }
}