import { createClient } from '@wallyhealth/web-core'
import config from '../../config'
  
const marketingClient = createClient({ baseUrl: config.MARKETING_KAPI_URL })


export const fetchConvertedLeads = ({ code, month, year }) => {

  const options = {
    params: {
      code,
      month,
      year
    }
  }
    
  return marketingClient.get(`/leads/stats`, options)
    .then(response => response.data)
}